<template>
  <div class="about_us">
    <div class="first">
      <div class="name_box">
        <div class="name">四川赛虎科技</div>
        <div class="line"></div>
        <div class="bref">
          我们致力于网络安全风险解决服务，以卓越的网络攻防技术，为客户提供网络安全保障，为企业发展保驾护航。
        </div>
      </div>
    </div>
    <div class="second">
      <div class="main_tit">
        <img src="../assets/about/arrow.png" alt="" height="40">
        <span class="cn">公司简介</span>
        <span>COMPANY PROFILE</span>
      </div>
      <div class="the_cnt">
        赛虎科技成立于2017年，是专业的网络安全风险解决服务提供商。秉承“服务持续稳定，守护网络安全”的理念，为客户提供优质的服务输出，包括“风险评估、安全建设、安全培训、安全咨询、应急保障、攻防对抗”等。
        公司技术服务团队拥有丰富的项目执行经验，并获得国内、国际各类安全能力认证资质，同时公司也获得了《信息安全风险评估》、《信息安全应急处理》、《信息安全管理体系》、《质量管理体系》等资质认证。
        业务区域覆盖全国，客户涉及金融、医疗、通信、电商、大数据、政府、能源、教育等行业。

      </div>
    </div>
    <div class="third">
      <div class="main_tit">
        <img src="../assets/about/arrow.png" alt="" height="40">
        <span class="cn">资质/荣誉</span>
        <span>QUALIFICATION HONOR</span>
      </div>
      <div class="the_ctn">     
        <div class="in_block">
          <div v-for="item in logos" :key="item" class="one">
            <img :src="getImg(item)" alt="" height="140">
            <div>{{item.slice(1)}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="fourth">
      <div class="main_tit">
        <img src="../assets/about/arrow.png" alt="" height="40">
        <span class="cn">合作案例</span>
        <span>COOPERTION CASE</span>
      </div>
      <div class="the_cnt">
        <img src="../assets/about/合作.png" alt="">
      </div>
    </div> -->
  </div>
</template>

<script>
import logos from '../utils/imgs.js'
export default {
  data(){
    return {
      logos:logos
    }
  },
  methods:{
    getImg(item) {
      return require('../assets/about/zs/'+`${item}`+'.png');
    },
  },
  created(){
    // console.log(logos)
  },
}
</script>

<style lang="scss" scoped>
  .about_us{
    .main_tit{
      display: flex;
      align-items: flex-end;
      color: $gray-base;
      margin-bottom: 16px;
      .cn{
        color: #000;
        font-size: 30px;
        margin: 0 6px;
      }
    }
    .the_cnt{
      color: $dark-8;
      line-height:2vw;
      letter-spacing: 1px;
      font-size: 1vw;
      img{
        width: 85vw;
      }
    }
    .first{
      color: #fff;
      width: 100%;
      height: 500px;
      background: url('../assets/about/公司简介.png') no-repeat;
      background-size: 100% 100%;
      padding-top: 160px;
      padding-left: 80px;
      .name_box{
        width: 520px;
        letter-spacing:2px;
        .name{
          font-size: 56px;
          font-weight: 900;
        }
        .line{
          width: 100%;
          height: 2px;
          background-color:$defult;
          margin: 10px 0 14px 0;
        }
      }
    }
    .second{
      padding: 80px;
    }
    .third,.fourth{
      padding: 0px 80px 80px;
      .in_block{
        display: flex;
        flex-wrap: wrap;
        color: $dark-8;
        text-align: center;
        margin-top: 40px;
        .one{
          width: 20%;
          margin-bottom: 40px;
        }
      }
    }
    .fourth{
      text-align: center;
      img{
        margin-top: 16px;
      }
    }
    @media screen and (max-width: 1080px){
      .main_tit{
        font-size: 12px;
        img{height: 25px;}
        .cn{
          font-size: 18px;
        }
      }
      .the_cnt{
        font-size: 14px;
        line-height: unset;
      }
      .first{
        padding: 50px 40px;
        height: unset;
        .name_box{
          width: 100%;
          .name{
            font-size: 32px;
          }
        }
      }
      .second,.third{
        padding: 40px;
        .in_block{
          .one{
            width: 33%;
            justify-content: space-between;
            font-size: 11px;
            img{height: 80px;}
          }
        }
      }
      .fourth{
        padding: 0px 40px 40px;
        .the_cnt{
          img{width: 100%!important;}
        }
      }
    }
  }
</style>